/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Milan"), React.createElement("p", null, "Pour se loger, Milan s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/milan/milan-centre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre historique"), " : les attractions touristiques sont concentrées autour du Duomo."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/milan/stazionecentrale.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare de Milan-Centrale"), ", au nord-est : près de Brera, du parc Sempione et du cimetière monumental."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/milan/cittastudi.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Città Studi"), ", à l’est : quartier étudiant situé entre Porta Venezia et Lambrate."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/milan/navigli.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Navigli"), ", au sud-ouest : le long de deux canaux, les quais accueillent de nombreux bars et restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/milan/fiera.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Fiera Milano City"), ", à l’ouest : quartier moderne de CityLife, dédié aux affaires, non loin du stade San Siro."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
